*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.wrapper {
  margin: auto;
  max-width: 960px;
  width: 960px;
}

#simulator-date .DayPicker {
  right: 0;
  top: 100%;
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    max-width: 100%;
    padding: 0 1rem;
    width: 100%;
  }
}
